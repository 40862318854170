<script>
import { computed } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import PayeeIcon from '/~/components/app/payee-icon.vue'
import { useLocalization } from '/~/composables/localization'
import ConfirmationPayScheduled from './confirmation-pay-scheduled.vue'

export default {
  name: 'confirmation-pay-to-payee',
  components: {
    PayeeIcon,
    ConfirmationPayScheduled,
  },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { formatBusinessNumber, translate, formatBsb } = useLocalization()

    const payee = computed(() => {
      return props.order.payee ?? {}
    })

    return {
      payee,
      formatBsb,
      formatBusinessNumber,
      formatDate,
      formatDollar,
      translate,
    }
  },
}
</script>

<template>
  <div>
    <div class="mb-2.5 flex w-full items-center justify-between">
      <div class="flex w-full items-center justify-center gap-x-4">
        <div>
          <payee-icon :payee="payee" :size="10" />
        </div>
        <div
          class="flex w-full flex-col gap-y-[5px] text-sm text-eonx-neutral-600"
        >
          <div class="text-base font-bold text-eonx-neutral-800">
            {{ payee.name }}
          </div>
          <div class="flex w-full justify-between">
            <div>
              <template v-if="payee.type === 'bankAccount'">
                {{
                  translate('bankAccount.details', {
                    acc: payee.accountNumber,
                    bsb: formatBsb(payee.bsb || ''),
                  })
                }}
              </template>
              <template v-else-if="payee.type === 'bpay'">
                Biller: {{ payee.billerCode }} | Ref:
                {{ order.reference }}
              </template>
            </div>
            <div class="text-sm">
              {{ formatDollar(order.subtotal) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirmation-pay-scheduled :order="order" />
  </div>
</template>
